import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Divider from "../../../../../components/divider"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LetterNote from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-note"
import LetterSidebar from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-sidebar"
import MoodBarometer from "../../../../../content/kurse/haelfte-des-lebens/02-entstehungsgeschichte/mood-barometer"
import AudioPlayer from "../../../../../components/audio-player"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/stimmungsbarometer/aufgabe-2" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>
          Zur Zeit der Entstehung von ›Hälfte des Lebens‹ war Hölderlin in
          seinen 30er Jahren. Von der Hälfte, beziehungsweise Mitte, seines
          Lebens spricht er auch in mehreren Briefen an Freunde und Verwandte.
          Stets steht er dabei unmittelbar vor Antritt einer neuen Stelle als
          Hauslehrer. Sie waren ihm Fluch und Segen zugleich: Einerseits
          entbanden sie ihn vom Pfarrberuf, dem er sich durch seine Schul- und
          Studienlaufbahn verpflichtet hatte, andererseits hätte Hölderlin seine
          Zeit lieber ausschließlich dem Schreiben und der Beschäftigung mit der
          griechischen Literatur und Kultur gewidmet.
        </Paragraph>
        <Paragraph>
          Lies die Briefe und beurteile, wie Hölderlin darin auf dich wirkt.
        </Paragraph>
        <LetterNote />
        <LetterSidebar
          before={<AudioPlayer src={`kurse/haelfte-des-lebens/brief-1`} />}
          main={
            <Stack space={6}>
              <p>
                Brief an den Bruder Karl vom Februar 1796, als Hölderlin gerade
                eine neue Hauslehrerstelle in der Frankfurter Bankiersfamilie
                Gontard angetreten hatte und sich schon bald darauf in die Frau
                des Hauses, Susette Gontard verliebte. Zu diesem Zeitpunkt war
                Hölderlin noch keine 26 Jahre alt.
              </p>
              <Divider size={4} />
              <>
                <p>Lieber Bruder!</p>
                <br />
                <p>
                  Ich danke Dir ganz herzlich für die brüderliche Theilnahme an
                  meinem Schiksaale, wie auch unserer lieben Mutter. Du hast
                  mich in bösen Tagen gesehn und Geduld mit mir gehabt, ich
                  wollte nun auch, Du könntest die fröhlichere Periode mit mir
                  theilen.
                </p>
                <p>
                  Es war auch Zeit, daß ich mich wieder etwas verjüngte; ich
                  wäre in der Hälfte meiner Tage zum alten Manne geworden. Mein
                  Wesen hat nun wenigstens ein paar überflüssige Pfunde an
                  Schwere verloren und regt sich freier und schneller, wie ich
                  meine.
                </p>
                <p>
                  Deus nobis haec otia fecit. [Vergil: Ein Gott hat uns diese
                  Muße geschaffen.] Du wirst mir das gönnen, Lieber! wirst nicht
                  gerade deßwegen denken, daß meine alte Liebe rosten werde über
                  meinem neuen Glük. Aber Glük wirst Du meine Lage auch nennen,
                  wenn Du selbst siehst und hörst, und das kann ich, wenigstens,
                  was die Reisekosten und Logis und Kost in Frankfurt betrift,
                  sehr bald und sehr leicht möglich machen.
                </p>
              </>
            </Stack>
          }
          sidebar={
            <MoodBarometer
              onChange={({ state }) => {
                setAnswer({
                  courseId: "haelfte-des-lebens",
                  chapterId: "02-entstehungsgeschichte",
                  taskId: "stimmungsbarometer-1",
                  answer: state,
                })
              }}
            />
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
